import React, { useState } from 'react'
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import validate from './validateForgotPassword';
import useFormForgotPassword from './useFormForgotPassword';
import { Markup } from 'interweave';

const useStyles = makeStyles((theme) => ({
  
  formButton:{
    "& ":{
      fontFamily: 'Barlow Condensed !important',
      backgroundColor: "#10aadf !important",
      width: 'fit-content',
      borderRadius: '10px',
      fontSize: '18px',
      marginBottom: "5px"
    },
    "& .MuiButton-label":{
      justifyContent: "left",
      padding: "10px",
      fontWeight: "600 !important",
      backgroundColor: "#10aadf",
      border: "0"
    }
  },
  formInput: {
    "&":{
      marginBottom: "5px"
    },
    "& .MuiFormLabel-filled":{
      color: "#10aadf",
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiInputBase-root ":{
      backgroundColor: "#d8f3fc",
      color: "#10aadf",
      outlineWidth: "0",
      width: "350px",
    }, 
    "& .MuiFilledInput-root": {
      "& input": { 
          borderColor: "#91ddf7"
      },
      "&.MuiFilledInput-underline:before": {
        borderBottom: "none"
      },
      "&.MuiFilledInput-underline:after": {
          borderBottom: "2px solid #10aadf"
      }
    },
}}));

function FormForgotPassword(props) {
  const { handleChange, handleSubmit, values, errors, messages, setIsSubmitting, isSubmitting } = useFormForgotPassword(
    props.submitForm,
    validate
  );

  const classes = useStyles();

  const [authToken] = useState(props.authenticity_token);
  const [recaptchaSiteKey] = useState(props.recaptcha_site_key);
  const recaptchaRef = React.createRef();

  const submitForm = async (e) =>{
    e.preventDefault();
    console.log("submit Forgot Password form");
    console.log("🌹 ");
    setIsSubmitting(true);
    console.log("AFTER SET IS SUBMITTING");
    // const token = await recaptchaRef.current.executeAsync();
    // console.log("AFTER TOKEN SET");
    handleSubmit(e, props.authenticity_token);
    console.log("AFTER HANDLE SUBMIT");
  }
  
  return (
    <div className="formForgotPassword" >
      {errors.error && <div className="form-error"><Markup content={errors.error} /></div>}
      <form onSubmit={submitForm}>
        <input type="hidden" value={authToken}/>
        <TextField id="filled-basic" label="Email" className={classes.formInput} name="email" error={errors.email !== undefined} helperText={errors.email !== "" ? errors.email : ' '} value={values.email} onChange={handleChange} variant="filled" />
        <br/>
        {isSubmitting ? 
          (<div><CircularProgress /><span>{messages.button ? messages.button :''}</span></div>)
          :
          <Button variant="contained" type="submit" color="primary" className={classes.formButton} >
            FORGOT PASSWORD
          </Button>
        }
        <br/>
        <a href="/user/login">Go Back to Login</a>
        <a href="/user/new">I need to set up an online account</a>
      </form>
    </div>
  )
}

export default FormForgotPassword
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  formButton:{
    "& ":{
      fontFamily: 'Barlow Condensed !important',
      backgroundColor: "#10aadf !important",
      width: 'fit-content',
      borderRadius: '10px',
      fontSize: '18px',
    },
    "& .MuiButton-label":{
      justifyContent: "left",
      padding: "10px",
      fontWeight: "600 !important",
      backgroundColor: "#10aadf",
      border: "0"
    }
  },
  formInput: {
    "&":{
      marginBottom: "5px"
    },
    "& .MuiFormLabel-filled":{
      color: "#10aadf",
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiInputBase-root ":{
      backgroundColor: "#d8f3fc",
      color: "#10aadf",
      outlineWidth: "0",
      width: "350px",
    }, 
    "& .MuiFilledInput-root": {
      "& input": { 
          borderColor: "#91ddf7"
      },
      "&.MuiFilledInput-underline:before": {
        borderBottom: "none"
      },
      "&.MuiFilledInput-underline:after": {
          borderBottom: "2px solid #10aadf"
      }
    },
}}));

function SuccessForgotPassword(props) {
  const classes = useStyles();
  
  const changeURL = (url) =>{
    window.location.href = url;
  }

  return (
    <div className="successSignUp" >
      <h5 className="subHeading">SUCCESS!</h5>
      <p>if {props.email} is an active account, you will receive an email with a link to reset your password.</p>
      <Button variant="contained" type="button" color="primary" className={classes.formButton} onClick={(e) => changeURL('/user/login')}>
        DONE
      </Button>
    </div>
  )
}

export default SuccessForgotPassword


export default function validateSignUpInfo(values) {
  console.log("VALIDATE --> 🥪 ");
  let errors = {};

  if (!values.zipCode) {
    errors.error = 'Zip Code is required';
    return errors;
  }

  if (!values.accountNumber) {
    errors.error = 'Account Number is required';
    return errors;
  }

  if (!values.email) {
    errors.error = 'Email required';
    return errors;
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.error = 'Email address is invalid';
    return errors;
  }
  if (!values.password) {
    errors.error = 'Password is required';
    return errors;
  } else if (values.password.length < 8 || values.password.length > 30) {
    errors.error = 'Password needs to be between 8 to 30 characters';
    return errors;
  }

  if(!values.rePassword){
    errors.error = 'ReType Password is required';
    return errors;
  }else if (values.rePassword != values.password){
    errors.error = 'Password does not match';
    return errors;
  }else if (values.password.search(/\d/) == -1) {
    errors.error = "Password doesnt match criteria, missing numbers";
    return errors;
  } else if (values.password.search(/[a-zA-Z]/) == -1) {
    errors.error = "Password doesnt match criteria, missing letters";
    return errors;
  } else if (values.password.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) != -1) {
    errors.error = "Password doesnt match criteria, invalid characters";
    return errors;
  }
  return errors;
}
import React, { useState, useEffect} from 'react';
import FormForgotPassword from './forms/FormForgotPassword';
import SuccessForgotPassword from './forms/SuccessForgotPassword'
import Session from './templates/Session';
import FormBanner from './shared/FormBanner';

const HeadingMessage = (props) =>{
  return(
    <>
      <div>Hi Member,</div>
      We're so please you've joined thousands of other New Yorkers who care about the helath and well-being of our planet, while saving money at the same time!
    </>
  )
}

const ForgotPassword = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(()=>{
    console.log("IS SUBMIT UE");
    console.log(isSubmitted);
    
  },[isSubmitted]);

  function submitForm() {
    console.log("IN FP SUBMIT FORM");
    setIsSubmitted(true);
    setEmail(email);
  }
  const forgotPasswordForm = () => {
    return (
      <>
        <Session 
          form={<FormForgotPassword submitForm={()=>{console.log("IN FP SUBMIT FORM");setIsSubmitted(true);}} {...props}/>} 
          formBanner = {<a href=" https://solarfarmsny.com/stepone/"><FormBanner {...props}/></a>}
          heading={<HeadingMessage {...props}/>} 
          {...props}
        />
      </>
    )
  }

  const forgotPasswordSuccess = () => {
    return (
      <>
        <Session form={<SuccessForgotPassword {...props}/>} 
          email={email} 
          formBanner = {<a href=" https://solarfarmsny.com/stepone/"><FormBanner {...props}/></a>}
          heading={<HeadingMessage {...props}/>} {...props}/>
      </>
    )
  }
  console.log("IS SUBMITTED", isSubmitted);

  return (
    <div className="signUp">
      {isSubmitted ?  forgotPasswordSuccess() : forgotPasswordForm() }
    </div>
  )
};

export default ForgotPassword
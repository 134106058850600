import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FormLogin from './forms/FormLogin';
import Session from './templates/Session';
import FormBanner from './shared/FormBanner';

const HeadingMessage = () =>{
  return(
    <>
      <div>Hi Member,</div>
      We're so pleased you've joined thousands of other New Yorkers who care about the health and well-being of our planet, while saving money at the same time!
    </>
  )
}

const FormFooterMesssage = () =>{
  return (
    <>
      <h5 className="subHeading">At this site you can easily manage your account:</h5>
      <nav>
        <ul>
          <li>Update your contact info </li>
          <li>Change your password</li>
          <li>Sign up for AutoPay</li>
          <li>See your statement savings</li>
          <li>Request copies of important documents</li>
        </ul>
      </nav>
      <h5 className="subHeading">Questions ?</h5>
      <nav>
        <ul>
          <li>Call Membership Services at (800) 641-2140</li>
        </ul>
      </nav>
    </>
    
  )
}
const MainPage = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  
  return (
    <div className="mainPage">
      <Session form={<FormLogin submitForm={submitForm} {...props}/>} formBanner = {<a href=" https://solarfarmsny.com/stepone/"><FormBanner {...props}/></a>} 
        formFooter={<FormFooterMesssage/>}
        heading={<HeadingMessage {...props}/>} {...props}/>
    </div>
  );
};

MainPage.propTypes = {
  name: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default MainPage

import React from 'react'
// In Progress
function SideMenu(props) {
  console.log("SideMenu PRINT PROPSSSSSS");
  console.log(props);
  const active_path = (
    (window.location.pathname.indexOf('/user/edit/payment') == 0) ? 'payment' :
    (window.location.pathname.indexOf('/user/details') == 0) ? 'account' : 'profile')

  const menu_items = {
    'profile': {'name': 'MY PROFILE', 'url': '/user'}, 
    'account': {'name': 'ACCOUNT DETAILS', 'url': props.customer ? `/user/details/${props.customer.uuid}`: '/user/details'}, 
    'payment': {'name': 'PAYMENT METHOD', 'url': "#"},
    'logout': {'name': props.admin ? 'RETURN':'LOGOUT', 'url': '/user/logout'}
  }
  
  return (
    <div className="sideMenu side-menu bg-menu-primary">
      <nav className="sideMenuItems">
        <li className={(active_path == 'profile') ? 'nav-item active': 'nav-item'}>
          <a href="/user" >MY PROFILE</a>
        </li>
    
        <li className={(active_path == 'account') ? 'nav-item active': 'nav-item'}>
          <a href={props.customer ? `/user/details/${props.customer.uuid}`: '/user/details'} >ACCOUNT DETAILS</a>
        </li>

        <li className={(active_path == 'payment') ? 'nav-item active': 'nav-item'}>
          <a href={`/user/edit/payment/${props.customer.uuid}`} >PAYMENT METHOD</a>
        </li>

        <li className="nav-item">
          <a href="/user/logout" >{props.admin ? 'RETURN':'LOGOUT'}</a>
        </li>
      </nav>
      <span className="nav-footer">
        <b>Solar Farms New York</b>
        <span className="nav-footer-small">
          <br/>90 State Street
          <br/>Albany NY, 1207
          <br/>(833) 877-7652
          <br/>CustomerCare@SolarFarmsNY.com
        </span>
      </span>
    </div>
  )
}

export default SideMenu